<template>
<div>
    <CNavbar expandable="md" class="bg-nav">
        <CToggler in-navbar @click="collapsed = !collapsed" />
        <CHeaderBrand class="mx-auto d-lg-none">
            <img :src="logo" class="img-fluid mt-0 mb-0" width="150px">
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
            <img :src="logo" class="c-sidebar-brand-full img-fluid my-2 ml-4" width="150px">
        </CHeaderNav>
        <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
                <span class="ml-3 mt-2 text-white h4 text-center">{{$route.meta.subTitle}}</span>
            </CNavbarNav>

        </CCollapse>

    </CNavbar>
    <CSubheader class="px-3">
        <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js"
export default {
    name: 'ContentHeader',

    data() {
        return {
            collapsed: false,
            logo: 'https://sig.im-ra.com/wp-content/uploads/2020/10/logo_sig_white-1024x375.png',
        }
    },

    created() {

    },
    methods: {
        obtenerCliente() {
            let me = this;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-datos-cliente", {
                        params: {
                            idCliente: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.setThemeColors(response.data[0].primaryColor, response.data[0].secondaryColor, response.data[0].accentColor)
                    me.logo = response.data[0].urlLogo
                })
                .catch(function (error) {
                    me.setThemeColors('#5d5d5d', '#ffffff', '#ffffff')
                });
        },
        setThemeColors(primario, secundario, accent) {
            let style = document.documentElement.style;
            style.setProperty("--bg-primary-color", primario);
            style.setProperty("--bg-secondary-color", secundario);
            style.setProperty("--bg-accent-color", accent);
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },

    computed: {

    },
    mounted() {
        if (this.$route.params.id) {
            this.obtenerCliente();
        } else {
            this.setThemeColors('#8c8c8c', '#ffffff', '#ffffff')
        }
    }
}
</script>

<style lang="scss">
.bg-primario {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
}

.modal-primario .modal-header {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color);
}

.card-accent-primario {
    border-top: 2px solid var(--bg-primary-color) !important;
}

.dropdown-active {
    background: rgba(0, 0, 0, 0.25) !important;
    // filter: brightness(85%);
    border-radius: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--bg-primary-color) !important;
}

.bg-nav {
    background-color: var(--bg-primary-color);
}

.table-custom thead th {
    color: var(--bg-secondary-color);
    background: var(--bg-primary-color);
    border-bottom: 0px;
}

/* ------------------------------- */

.bootstrap-datetimepicker-widget table td span.active,
.bootstrap-datetimepicker-widget table td i.active {
    background-color: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td i:hover {
    background-color: var(--bg-primary-color);
    color: var(--bg-secondary-color) !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

/* ------------------------------- */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bg-secondary-color) !important;
    background-color: var(--bg-primary-color) !important;
}

.nav-link {
    color: var(--bg-primary-color);
}

.nav-link:hover {
    color: var(--bg-primary-color);
}

/* ------------------------------- */

.vs__dropdown-option:hover {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

.vs__dropdown-option--highlight {
    background: var(--bg-primary-color) !important;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */

a {
    text-decoration: none;
    background-color: transparent;
    color: var(--bg-primary-color);
}

a:hover {
    background-color: transparent;
    color: var(--bg-primary-color);
}

/* ------------------------------- */

.btn-custom {
    color: var(--bg-primary-color);
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    //F9F9F9
}

.btn-custom:hover {
    color: #fff;
    background-color: var(--bg-primary-color);
    border-color: var(--bg-primary-color);
}

.btn-custom:focus,
.btn-custom.focus {
    box-shadow: 0 0 0 0px var(--bg-primary-color);
}

.btn-custom.disabled,
.btn-custom:disabled {
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.btn-custom:active,
.btn-custom.active,
.show>.btn-custom.dropdown-toggle {
    background-color: var(--bg-primary-color);
    background-image: none;
    border-color: var(--bg-primary-color);
}

.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */
.btn-datepicker {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:hover {
    color: var(--bg-primary-color);
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:focus,
.btn-datepicker.focus {
    box-shadow: 0 0 0 0px var(--bg-secondary-color) !important;
}

.btn-datepicker.disabled,
.btn-datepicker:disabled {
    background-color: var(--bg-secondary-color) !important;
    border-color: var(--bg-secondary-color) !important;
}

.btn-datepicker:active,
.btn-datepicker.active,
.show>.btn-datepicker.dropdown-toggle {
    background-color: var(--bg-secondary-color) !important;
    background-image: none;
    border-color: var(--bg-secondary-color) !important;
}

.input-custom {
    width: 45px;
    height: 30px;
    color: transparent;
    text-shadow: 0 0 0 var(--bg-primary-color);
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    text-align: center;
    font-weight: bolder;
}

/* ------------------------------- */

.icon-custom .ico {
    color: #cfcfcf;
}

.icon-custom:hover .ico {
    color: var(--bg-primary-color);
}

/* ------------------------------- */

.bg-accordion,
.bg-accordion:focus {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color);
    padding: 5px;
    outline: 0px !important;
}

.bg-accordion-transparent,
.bg-accordion-transparent:focus {
    background: var(--bg-primary-color);
    color: var(--bg-secondary-color);
    filter: opacity(70%);
    padding: 5px;
    outline: 0px !important;
}

.drop-custom {
    color: var(--bg-secondary-color);
}

.drop-custom:hover {
    color: var(--bg-secondary-color);
    filter: brightness(80%);
}
</style><style>
.dropdown-toggle::after {
    display: none
}

.dropdown-icon {
    font-size: 0.8rem
}

.dropdown-icon:hover {
    font-size: 0.83rem
}

.navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
</style><style lang="scss">
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: rgba(255, 255, 255, 0);
        color: #FFFF !important;
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        max-width: 250px;
    }

    &[x-placement^="top"] {

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 10px;

        .tooltip-arrow {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s;
    }
}
</style>
