<template>
<div class="c-app">
    <CWrapper>
        <ContentHeader />
        <div class="c-body">
            <main class="c-main">
                <!-- {{token}} -->
                <CContainer fluid>
                    <!-- <notifications position="bottom right" /> -->
                    <transition name="fade" mode="out-in">
                        <router-view :key="$route.path">
                        </router-view>
                    </transition>
                </CContainer>
            </main>
        </div>
        <ContentFooter />
    </CWrapper>
</div>
</template>

<script>
import ContentHeader from './ContentHeader'
import ContentFooter from '../ContentFooter'

export default {
    name: 'ContentLayout',
    components: {
        ContentHeader,
        ContentFooter
    },
    data() {
        return {
          
        }
    },
    methods: {

    },
    computed: {

    },
    watch: {

    },
    mounted() {
     
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/*Custom styles*/
</style><style>
/*----------button azul-------*/

.btn-blue {
    background: #2d2d52;
    border-color: #2d2d52;
    color: #ffffff;
}

.btn-blue:hover {
    color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}

.btn-blue:focus,
.btn-blue.focus {
    color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}
.btn-blue:active,
.btn-blue.active {
   color: #ffffff;
    background: #1f1f3a;
    border-color: #32325d;
}

.btn-blue.disabled,
.btn-blue:disabled {
    background-color: #232335;
    border-color: #232335;
}

/*-------------------*/

.page-item.active .page-link {
    background: #636f83 !important;
    border-color: #636f83 !important;
}

.ql-image,
.ql-video {
    visibility: hidden;
}

/*----------objetivos - indicadores / -------*/

.slash-space {
    padding-right: 5px;
    font-size: 20px;
    padding-left: 5px;
}

/*--------------mapa de procesos - title----------- */

.flex-title {
    font-weight: lighter;
    font-size: 24px;
    color: #FFF;
    background-color: #356AA0;
    padding: 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    font-weight: bold;
    display: inline-block;
    position: relative
}

.flex-title::after {
    content: '';
    display: inline-block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #356AA0;
    position: absolute;
    bottom: -15px;
    left: 45%;
}

/*--------------mapa de procesos - container----------- */

.flex-container {
    margin-top: 20px;
    border: 5px solid #356AA0;
    padding: 20px 10px;
    border-radius: 12px;
    position: relative;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

/*--------------mapa de procesos - childs----------- */

.flex-child {
    padding: 20px;
    margin-left: 90px;
    border-radius: 12px;
    position: relative;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.border-flex-child-green {
    border: 5px dashed #6BBA70;
}

.border-flex-child-orange {
    border: 5px dashed #d4864f;
}

.border-flex-child-yellow {
    border: 5px dashed #C79810;
}

/*--------------mapa de procesos - childs-arrows----------- */

.border-flex-child-green:after {
    content: '';
    display: inline-block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #6BBA70;
    position: absolute;
    bottom: -25px;
    left: 49%;
}

.border-flex-child-yellow:after {
    content: '';
    display: inline-block;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #C79810;
    position: absolute;
    top: -25px;
    left: 49%;
}

/*--------------mapa de procesos - sub-childs----------- */

.flex-sub-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: #ffffff;
    width: auto;
    display: inline-block;
    font-size: 18px;
    padding: 10px;
    border-radius: 6px;
    margin: 10px;
}

.content-flex-sub-child-green {
    background: #6BBA70;
}

.content-flex-sub-child-orange {
    background: #d4864f;
}

.content-flex-sub-child-yellow {
    background: #C79810;
}

/*--------------mapa de procesos - burbles----------- */

.flex-bubble-lateral {
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    left: -110px;
    z-index: 10;
    padding: 20px;
    border-radius: 100%;
    color: #FFF;
}

.flex-bubble-lateral:after {
    content: '';
    display: inline-block;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    bottom: 40%;
    right: -18px;
}

.flex-bubble-lateral-green {
    background-color: #6BBA70;
    top: 40%;
}

.flex-bubble-lateral-green:after {
    border-left: 10px solid #6BBA70;
}

.flex-bubble-lateral-orange {
    background-color: #d4864f;
    top: 40%;
}

.flex-bubble-lateral-orange:after {
    border-left: 10px solid #d4864f;
}

.flex-bubble-lateral-yellow {
    background-color: #C79810;
    top: 40%;
}

.flex-bubble-lateral-yellow:after {
    border-left: 10px solid #C79810;
}

.flex-bubble-lateral i {
    font-size: 30px;
    position: relative;
    bottom: 8px;
}

/*--------------mapa de procesos - burbles-primary----------- */

.flex-bubble-lateral-primary {
    position: relative;
    top: 45%;
}

.flex-bubble-lateral-primary div {
    background-color: #356AA0;
    color: #FFF;
    padding: 13px;
    display: inline-block;
    border-radius: 40%;
}

.flex-bubble-lateral-primary i {
    font-size: 50px;
    margin-bottom: 10px;
    display: inline-block;
    color: #356AA0;
}

.flex-bubble-lateral-primary-left:after {
    content: '';
    display: inline-block;
    border-left: 0px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 30px solid #356AA0;
    position: relative;
    bottom: 18px;
    left: 10px;
}

.flex-bubble-lateral-primary-right:after {
    content: '';
    display: inline-block;
    border-right: 0px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 30px solid #356AA0;
    position: relative;
    bottom: 18px;
    left: -10px;
}

@media only screen and (max-width : 1224px) {
    .flex-bubble-lateral-primary {
        display: none;
    }
}

/*----------estadisticas-------*/
.lg-numero {
    font-size: 30px;
    font-weight: bold;

}

/*----------v-select-------*/

.vs__selected {
    color: #768192 !important;
}

.vs__search,
.vs__search:focus {
    color: #a0a1a2 !important
}

.vs__dropdown-option {
    color: #626b77 !important;
}

.vs__clear {
    visibility: hidden;
}

.style-invalid-select .vs__dropdown-toggle {
    border-color: #e55353 !important;
}

.style-valid-select .vs__dropdown-toggle {
    border-color: #2eb85c !important;
}

.vs__dropdown-toggle {
    border-color: #d8dbe0 !important;
    border: 1px solid;

}

/*----------informe global-------*/

.hidden_header {
    display: none;
}

/*----------perfil- tabs-------*/

.disabled-white {
    background-color: #ffffff !important;
    color: rgb(118, 129, 146) !important;
}

/*---------- vue tour-------*/
.estadisticasitem {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

/*---------- router-link-------*/
.router-link-none,
.router-link-none:hover {
    color: #636f83;
    text-decoration: none;

}

/*----------Hidden Modal footer----*/

.modal-footer {
    display: none;
}

/*----------hr vertical----*/
.vr {
    border-right: 1px solid #CCC;
}

ul:focus, ul:focus-visible {
        outline: -webkit-focus-ring-color auto 0px !important;
}

/*----------accordion----*/
.bg-accordion-danger, .bg-accordion-danger:focus {
    background: #e55353;
    padding: 5px;
    outline: 0px;
}
.bg-accordion-success, .bg-accordion-warning:focus {
    background: #2eb85c;
    padding: 5px;
    outline: 0px;
}
.bg-accordion-warning, .bg-accordion-warning:focus {
    background: #f9b115;
    padding: 5px;
    outline: 0px;
}


</style>
